import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Register from './Register';
import Home from './Home';
import MapComponent from './Map';
import Surpriseme from './Surpriseme';
import News from './News';
import Game from './Game';
import Quiz from './Quiz';
import VirtualStudy from './virtualstudy';
import Favorites from './Favorite';
import Layout from './Layout';
import UserProfile from './UserProfile';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/Register" />} />
      <Route path="/Register" element={<Register />} />
      <Route element={<Layout />}>
        <Route path="/Home" element={<Home />} />
        <Route path="/Map" element={<MapComponent />} />
        <Route path="/Surpriseme" element={<Surpriseme />} />
        <Route path="/News" element={<News />} />
        <Route path= "/Quiz" element={<Quiz />} />
        <Route path="/Game" element={<Game />} />
        <Route path="/virtualstudy" element={<VirtualStudy />} />
        <Route path="/Favorite" element={<Favorites />} />
        <Route path="/UserProfile" element={<UserProfile />}  />
      </Route>
    </Routes>
  );
}

export default App;
