import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc, setDoc, arrayRemove } from 'firebase/firestore';
import './Favorite.css';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchFavorites = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const docRef = doc(db, 'users', userId); 
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFavorites(docSnap.data().favorites || []);
        }
      }
      setLoading(false);
    };

    fetchFavorites();
  }, []);

  const removeFavorite = async (shop) => {
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      const docRef = doc(db, 'users', userId); 

      try {
        await setDoc(
          docRef,
          {
            favorites: arrayRemove(shop),
          },
          { merge: true }
        );

        setFavorites((prevFavorites) =>
          prevFavorites.filter(
            (fav) => fav.name !== shop.name || fav.address !== shop.address
          )
        );

        setNotification('Removed from Favorites successfully');
        setTimeout(() => setNotification(null), 3000); 
      } catch (error) {
        console.error('Error removing from favorites: ', error);
        alert('Failed to remove from favorites.');
      }
    }
  };

  const handleDirections = (shop) => {
    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${shop.lat},${shop.lng}`;
    window.open(directionsUrl, '_blank');
  };

  if (loading) {
    return <div>Loading your favorites...</div>;
  }

  if (!favorites.length) {
    return <div style={{ marginTop: '100px' }}>No favorites yet. Go add some from the map!</div>;
  }

  return (
    <div className="main-content">
      <div className="favorites-page">
        <h1>Your Favorite Coffee Shops</h1>
        <div className="favorites-list">
          {favorites.map((shop, index) => (
            <div key={index} className="favorite-item">
              <h2>{shop.name}</h2>
              <p><strong>Address:</strong> {shop.address}</p>
              <p><strong>Rating:</strong> {shop.rating || 'N/A'}</p>
              <p><strong>Distance:</strong> {shop.distance} miles away</p>
              <button onClick={() => handleDirections(shop)} className="directions-button">Get Directions</button>
              <button onClick={() => removeFavorite(shop)} className="remove-favorite-button">Remove from Favorites</button>
            </div>
          ))}
        </div>
      </div>

      {notification && (
        <div className="notification">
          <span>{notification}</span>
          <button onClick={() => setNotification(null)} className="notification-close">X</button>
        </div>
      )}
    </div>
  );
};

export default Favorites;
