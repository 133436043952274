import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './Register.css';

function Register() {
  const [isRegister, setIsRegister] = useState(false);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [notification, setNotification] = useState('');
  const navigate = useNavigate();

  const showNotification = (message, duration = 3000) => {
    setNotification(message);
    setTimeout(() => setNotification(''), duration);
  };

  const handleFormSwitch = (showRegister) => {
    setIsRegister(showRegister);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const email = event.target.elements['register-email'].value;
    const password = event.target.elements['register-password'].value;
    const username = event.target.elements['register-username'].value;

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        username: username,
        createdAt: new Date(),
        quizResponses: [],
        favorites: [],
      });

      showNotification('User registered successfully!');
      handleFormSwitch(false);
    } catch (error) {
      showNotification(`Error: ${error.message}`);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = event.target.elements['login-email'].value;
    const password = event.target.elements['login-password'].value;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      navigate('/Home');

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          createdAt: new Date(),
          quizResponses: [],
        });
      }

      showNotification('Login successful!');
    } catch (error) {
      showNotification(`Error: ${error.message}`);
    }
  };

  const handleGuestContinue = () => {
    navigate('/home');
    showNotification('Continuing as guest.');
  };

  return (
    <div className="register-background">
      {notification && <div className="reg-notification">{notification}</div>}
      {isWelcomeScreen ? (
        <div className="welcome-container">
          <h1 className="welcome-header">Welcome to Coffee Crave!</h1>
          <div className="options-container">
            <div className="option-box" onClick={() => setIsWelcomeScreen(false)}>
              <h2>Login</h2>
            </div>
            <div className="option-box" onClick={handleGuestContinue}>
              <h2>Continue as Guest</h2>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-container">
          <button className="back-button" onClick={() => setIsWelcomeScreen(true)}>
            Back to Options
          </button>
          <div id="loginFormContainer" className={`form-container ${isRegister ? 'hidden' : ''}`}>
            <h2 className="headerreg">Find your taste today!</h2>
            <form id="loginForm" onSubmit={handleLogin}>
              <h1 className="login-color">Login:</h1>
              <div className="form-group">
                <label htmlFor="login-email" className="login-color">Enter Email:</label>
                <input type="email" id="login-email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="login-password" className="login-color">Enter Password:</label>
                <input type="password" id="login-password" name="password" required />
              </div>
              <button className="subbutton" type="submit">Login</button>
              <p className="login-color">Don't have an account? <button type="button" className="link-button" onClick={() => handleFormSwitch(true)}>Register here</button></p>
            </form>
          </div>

          <div id="registerFormContainer" className={`form-container ${isRegister ? '' : 'hidden'}`}>
            <h2 className="headerreg">Create an Account</h2>
            <form id="registerForm" onSubmit={handleRegister}>
              <h1 className="login-color">Find your taste today!</h1>
              <div className="form-group">
                <label htmlFor="register-email" className="login-color">Enter Email:</label>
                <input type="email" id="register-email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="register-username" className="login-color">Enter Username:</label>
                <input type="text" id="register-username" name="username" required />
              </div>
              <div className="form-group">
                <label htmlFor="register-password" className="login-color">Enter Password:</label>
                <input type="password" id="register-password" name="password" required />
              </div>
              <button className="subbutton" type="submit">Create Account</button>
              <p className="login-color"><strong>Already have an account?</strong> <button type="button" className="link-button" onClick={() => handleFormSwitch(false)}>Login here</button></p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
