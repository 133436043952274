import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { auth } from './firebase';

function Navbar() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleMenu = () => {
    if (isLoggedIn) {
      setMenuOpen(prevMenuOpen => !prevMenuOpen);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setIsLoggedIn(false);
      setMenuOpen(false);
      navigate('/Register');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <header className="navbar">
      <h1 className="navbar-title" onClick={() => navigate('/Home')}>Coffee Crave</h1>

      {isLoggedIn ? (
        <span className="favorites-link" onClick={() => navigate('/Favorite')}>
          Favorites
        </span>
      ) : (
        <span className="favorites-link" onClick={() => navigate('/Register')}>
          Sign in here
        </span>
      )}

      <div className="hamburger-icon" onClick={toggleMenu}>
        <img
          src={menuOpen ? '/coffee_spill.png' : '/coffee_still.png'}
          alt="Menu Icon"
        />
      </div>

      {menuOpen && isLoggedIn && (
        <nav className="nav-menu">
          <button className="mobile-menu" onClick={() => { navigate('/Game'); setMenuOpen(false); }}>Take a Break</button>
          <button onClick={() => { navigate('/News'); setMenuOpen(false); }}>Coffee News</button>
          <button onClick={() => { navigate('/quiz'); setMenuOpen(false); }}>Coffee Quiz</button>
          <button onClick={() => { navigate('/virtualstudy'); setMenuOpen(false); }}>Virtual Study</button>
          <button className="mobile-menu" onClick={() => { navigate('/Surpriseme'); setMenuOpen(false); }}>Surprise Me</button>
          <button onClick={handleLogout}>Logout</button>
        </nav>
      )}
    </header>
  );
}

export default Navbar;
