import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useNavigate, useLocation } from "react-router-dom";
import InfoPanel from "./InfoPanel";
import "./Map.css";
import { useAuth } from "./context/AuthContext";

const libraries = ["places", "geometry"];

const MapComponent = () => {
  const [coffeeShops, setCoffeeShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading: authLoading } = useAuth();

  const mapStyles = { height: "100vh", width: "100%" };
  const defaultCenter = { lat: 40.7128, lng: -74.0060 };

  const defaultOptions = {
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: false,
    disableDefaultUI: true,
    styles: [
      { featureType: "all", elementType: "labels", stylers: [{ visibility: "on" }] },
      { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] },
    ],
  };

  useEffect(() => {
    if (currentUser) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoading(false);
        },
        (error) => {
          console.error("Geolocation Error:", error);
          setLoading(false);
        }
      );
    } else {
      setUserLocation(defaultCenter);
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (location.state?.selectedShop) {
      setSelectedShop(location.state.selectedShop);
    }
  }, [location.state]);

  const fetchCoffeeShops = (map) => {
    if (!map || !window.google) {
      console.error("Map or Google API is not initialized.");
      return;
    }
  
    const location = userLocation || defaultCenter;
  
    try {
      const service = new window.google.maps.places.PlacesService(map);
  
      const request = {
        location: new window.google.maps.LatLng(location.lat, location.lng),
        radius: 4000,
        types: ['cafe', 'coffee', 'brewery', 'espresso_bar', 
        'coffeehouse', "bistro", 'specialty_coffee', 'coffee_stand'],
      };
  
      service.nearbySearch(request, (results, status) => {
        if (status === undefined) {
          console.error("Status is undefined from Places API");
          return;
        }
  
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error("Places API Error:", status);
          return;
        }
  
        const filteredPlaces = results
          .filter((place) => {
            const excludedKeywords = [
              "starbucks",
              "dunkin",
              "mcdonald's",
              "sushi",
              "bubble",
              "deli",
              "restaurant",
              "supermarket",
            ];
            return !excludedKeywords.some((keyword) =>
              place.name.toLowerCase().includes(keyword)
            );
          })
          .map((place) => ({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.name,
            address: place.vicinity,
            rating: place.rating,
            distance: userLocation
              ? calculateDistance(userLocation, place.geometry.location)
              : null,
            amenities: place.types,
            place_id: place.place_id,
          }));
  
        setCoffeeShops(filteredPlaces);
      });
    } catch (error) {
      console.error("Error fetching coffee shops:", error);
    }
  };
  

  const calculateDistance = (userLoc, shopLoc) => {
    if (!window.google || !window.google.maps.geometry) return 0;

    const userLatLng = new window.google.maps.LatLng(userLoc.lat, userLoc.lng);
    const shopLatLng = new window.google.maps.LatLng(shopLoc.lat(), shopLoc.lng());
    const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
      userLatLng,
      shopLatLng
    );
    return (distanceInMeters / 1609.344).toFixed(2);
  };

  const handleMarkerClick = (shop) => {
    setSelectedShop(shop);
    navigate("/map", { state: { selectedShop: shop } });
  };

  const centerMap = () => {
    if (mapRef.current && userLocation) {
      mapRef.current.panTo(userLocation);
    }
  };
  
  const handleMapLoad = (map) => {
    if (!map) {
      console.error("Map failed to load.");
      return;
    }

    mapRef.current = map;
    fetchCoffeeShops(map);
  };

  return (
    <div className="map-container">
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        libraries={libraries}
        onLoad={() => setMapsLoaded(true)}
      >
        {loading || authLoading || !mapsLoaded ? (
          <div className="loading-container">
            <img
              src="/loading-coffee.gif"
              alt="Loading..."
              className="loading-gif"
            />
          </div>
        ) : (
          <>
            <div style={{ position: 'relative', width: '100%' }}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                center={userLocation || defaultCenter}
                zoom={13}
                options={defaultOptions}
                onLoad={(map) => {
                  mapRef.current = map;
                  fetchCoffeeShops(map);
                }}
              >
                {userLocation && (
                  <Marker
                    position={userLocation}
                    icon={{
                      url: "/usermarker.png",
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                  />
                )}
                {coffeeShops.map((shop, index) => (
                  <Marker
                    key={index}
                    position={{ lat: shop.lat, lng: shop.lng }}
                    icon={{
                      url: "/markericon.png",
                      scaledSize: new window.google.maps.Size(45, 45),
                    }}
                    onClick={() => handleMarkerClick(shop)}
                  />
                ))}
              </GoogleMap>
              <button onClick={centerMap} className="center-map-button">
                Center Map
              </button>
            </div>
            {selectedShop && (
              <InfoPanel shop={selectedShop} onClose={() => setSelectedShop(null)} />
            )}
          </>
        )}
      </LoadScript>
    </div>
  );    
};

export default MapComponent;