import React, { useState, useEffect, useRef } from 'react';
import './virtualstyle.css';

function VirtualStudy() {
  // States for task input and task list
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  
  const openTimer = useRef(null);
  const closeTimer = useRef(null);
  const openInfo = useRef(null);
  const closeInfo = useRef(null);

  const timerModal = useRef(null);
  const infoModal = useRef(null);

  const [stopwatchRunning, setStopwatchRunning] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerDuration, setTimerDuration] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isPomodoro, setIsPomodoro] = useState(false);
  const [inWorkSession, setInWorkSession] = useState(true);
  const [showTimerOptions, setShowTimerOptions] = useState(false);

  // Load the tasks
  useEffect(() => {
    const savedTasks = localStorage.getItem("tasks");
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  // Add task
  const addTask = () => {
    if (task.trim() === '') {
      alert("Can't add empty text!");
      return;
    } else {
      const newTasks = [{id: Date.now(), text: task, checked: false }, ...tasks];
      setTasks(newTasks);
      setTask(''); // clear after adding
      saveData(newTasks);
    }
  };
  
  // Save data
  const saveData = (tasks) => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  };

  // Toggle completion of the task
  const toggleTask = (id) => {
    const newTasks = tasks.map((task) =>
      task.id === id ? { ...task, checked: !task.checked } : task
    );
    // const newTasks = [...tasks];
    newTasks[id].checked = !newTasks[id].checked;
    setTasks(newTasks);
    saveData(newTasks);
  };

  // Delete task
  const deleteTask = (id) => {
    const newTasks = tasks.filter((_, i) => i !== id);
    setTasks(newTasks);
    saveData(newTasks);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.id === "enter-task") {
      addTask();
    }
  };

  const clearLog = () => {
    setTasks([]); // Clear the tasks array
    saveData([]); // Update localStorage with an empty array
  };

  // AUDIO SLIDERS 
  const handleAudioSlider = (audioId, sliderValue) => {
    const audio = document.getElementById(audioId);
    audio.volume = sliderValue;
    if (sliderValue > 0) {
      audio.play();
    } else {
      audio.pause();
    }
  };
  // INFO BUTTON
  const handleOpenInfo = () => {
    if (infoModal.current) {
      infoModal.current.classList.add('show');
    }
  };

  const handleCloseInfo = () => {
    if (infoModal.current) {
      infoModal.current.classList.remove('show');
    }
  };

  // TIMER BUTTON
  const handleOpenTimer = () => {
    if (timerModal.current) {
      timerModal.current.classList.add('show');
    }
  };

  const handleCloseTimer = () => {
    if (timerModal.current) {
      timerModal.current.classList.remove('show');
    }
  };

  // TIMER
  const formatTime = (totalSeconds) => {
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`
  };

  const startStopwatch = () => { setSeconds(0); setStopwatchRunning(true); };

  const setTimer = () => {const minutes = prompt("Enter timer duration in minutes:");
    if (minutes !== null) {const duration = parseInt(minutes) * 60;
      setTimerDuration(duration); setTimerRunning(true); setShowTimerOptions(false);}
  };

  const stopTimer = () => { setStopwatchRunning(false); setTimerRunning(false);
  setIsPomodoro(false); setShowTimerOptions(false); };

  const resetTimer = () => { setSeconds(0); setTimerDuration(0); setStopwatchRunning(false); setTimerRunning(false);
    setIsPomodoro(false);
    setInWorkSession(true);
    setShowTimerOptions(false);
  };

  const startPomodoro = () => {
    setTimerDuration(25 * 60);
    setIsPomodoro(true);
    setInWorkSession(true);
    setTimerRunning(true);
    setShowTimerOptions(false);
  };

  const toggleTimerOptions = () => {
    setShowTimerOptions(!showTimerOptions);
  };

  useEffect(() => {
    let interval;
    if (stopwatchRunning) {
      interval = setInterval(() => setSeconds((prev) => prev + 1), 1000);}
    if (timerRunning && timerDuration > 0) {
      interval = setInterval(() => setTimerDuration((prev) => prev - 1), 1000);}
    return () => clearInterval(interval);}, [stopwatchRunning, timerRunning, timerDuration]);

  useEffect(() => {
    if (isPomodoro && timerDuration === 0) {
      if (inWorkSession) {
        alert("Time for a break!");
        setTimerDuration(5 * 60);
        setInWorkSession(false);
      } else {
        alert("Back to work!");
        setTimerDuration(25 * 60);
        setInWorkSession(true);
      }}}, [timerDuration, isPomodoro, inWorkSession]);

  // Spotify

    return (
    <body className= "VS-page">
    <div className = "main-content">
      <div className="backdrop">
      <div id="virtual-header">
        <h1>Coffee Crave</h1>
        {/* insert potential logo here maybe pixel cup*/}
        <h4>grab a cup of coffee and be productive</h4>
      </div>

      <div className="to-do-list">
        <h2 id="task-title">Tasks List <img src="/virtualstudy/resources/images/tasklist.png" alt="Task List" /></h2>
        <div className="add-row">
          <input type="text" id="enter-task" value={task} placeholder="what's next?" onChange={(e) => setTask(e.target.value)} onKeyDown={handleKeyDown}/>
          <button id="add-button" onClick={addTask}>Add</button>
          <button id="clear-log" onClick={clearLog}>Clear All</button> 
        </div>
        <div className="entries-container">
          <ul className="entries-container" id="list-container">
            {tasks.map((t, index) => ( 
            <li key={index} className={t.checked ? "checked" : ""}> 
              <input type="checkbox" id={`task-checkbox-${index}`} checked={t.checked} onChange={() => toggleTask(index)} />
              <label htmlFor={`task-checkbox-${index}`}></label>
              <span className="task-text">{t.text}</span> {/* Wrap the text */}
              <button id="delete-task" onClick={() => deleteTask(index)}>x</button>
            </li> // render the tasks
            ))}
          </ul>
        </div>
      </div>

      {/* Audio Sliders */}
      <div id="AudioFilters">
        {/* Rain Sounds */}
        <div className="slider-container">
          <h3>Rain</h3>
          <input type="range" min="0" max="1" step="0.01" defaultValue="0" className="audio-slider" id="rain-slider" onChange={(e) => handleAudioSlider('rain-audio', e.target.value)}/>
          <audio id="rain-audio" loop src="/virtualstudy/resources/audios/rainshortened - Made with Clipchamp.mp4"></audio>
        </div>

        {/* Fire Crackling */}
        <div className="slider-container">
          <h3>Fire Crackling</h3>
          <input type="range" min="0" max="1" step="0.01" defaultValue="0" className="audio-slider" id="fire-slider" onChange={(e) => handleAudioSlider('fire-audio', e.target.value)} />
          <audio id="fire-audio" loop src="/virtualstudy/resources/audios/firecrackling.wav"></audio>
        </div>

        {/* Ocean Waves */}
        <div className="slider-container">
          <h3>Ocean Waves</h3>
          <input type="range" min="0" max="1" step="0.01" defaultValue="0" className="audio-slider" id="ocean-slider" onChange={(e) => handleAudioSlider('ocean-audio', e.target.value)} />
          <audio id="ocean-audio" loop src="/virtualstudy/resources/audios/oceanwaves.mp4"></audio>
        </div>

        {/* Birds */}
        <div className="slider-container">
          <h3>Birds</h3>
          <input type="range" min="0" max="1" step="0.01" defaultValue="0" className="audio-slider" id="birds-slider" onChange={(e) => handleAudioSlider('birds-audio', e.target.value)}/>
          <audio id="birds-audio" loop src="/virtualstudy/resources/audios/birdsong.wav"></audio>
        </div>

      </div>


      {/* SPOTIFY API or EMBED */}
      <div id="Spotify">
        <iframe
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/playlist/4pLS8MzMx0WW9Msstwu8Nw?utm_source=generator"
          width="100%"
          height="350"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          title="Spotify Playlist"
        ></iframe>
      </div>

      {/* TIMER BUTTON */}
      <button id="open-timer"className="virtual-buttons" ref={openTimer} onClick={handleOpenTimer}></button>
      <div className="modal-container" ref={timerModal}>
        <div id="timer-modal" className="modal">
          <h1>Timer</h1>
          <div className="timer-container">
            <div id="timer-display" className="timer-display">
              {stopwatchRunning ? formatTime(seconds) : formatTime(timerDuration)}
            </div>
            <div className="timer-buttons">
              <button id="start-stopwatch" className="timer-buttons" onClick={startStopwatch}>Stopwatch</button>
              {!showTimerOptions ? (
                <button id="start-timer" className="timer-buttons" onClick={toggleTimerOptions}>Start Timer</button>
                ) : (
                <>
                <button id="start-regular" className="timer-buttons" onClick={setTimer}>Start Regular</button>
                <button id="start-pomodoro" className="timer-buttons" onClick={startPomodoro}>Start Pomodoro</button>
                </>
              )}
              <button id="stop-timer" className="timer-buttons" onClick={stopTimer} style={{ display: timerRunning || stopwatchRunning ? "inline" : "none" }}>Stop</button>
              <button id="reset-timer" className="timer-buttons" onClick={resetTimer}>Reset</button>
            </div>
            <button id="close-timer" className="close-modal" ref={closeTimer} onClick={handleCloseTimer}>Close</button>
          </div>
          
        </div>
      </div>

      {/* Info/About */}
      <button id="open-info" className="virtual-buttons" ref={openInfo} onClick={handleOpenInfo}></button>
      <div className='modal-container' ref={infoModal}>
        <div id="info-modal" className='modal'>
          <button id="close-info" className='close-modal' ref={closeInfo} onClick={handleCloseInfo}>X</button>
          <h1>about virtual study</h1>
          <p>your own personal little study space. <br></br>
            keep track of tasks, time your goals, adjust the audio filters to your liking <br></br>
            to create your ideal work ambience <br></br>          
          </p>
          <div className="info-coffee">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="info-cup"><span></span></div>
          </div>
        </div>

      </div>

    {/* This next tag closes the backdrop div  */}
      </div> 
    </div>
    </body>
    );
}
export default VirtualStudy;