import React, { useState, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import MapComponent from './Map.js';
import { useAuth } from './context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

function Home() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isQuizComplete, setIsQuizComplete] = useState(false);
  const [isGuest, setIsGuest] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setIsLoggedIn(true);
      setIsGuest(currentUser.isGuest || false);
      
      const fetchQuizStatus = async () => {
        try {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setIsQuizComplete(docSnap.data().quizCompleted || false);
          } else {
            setIsQuizComplete(false);
          }
        } catch (error) {
          console.error('Error fetching quiz status: ', error);
          setIsQuizComplete(false);
        }
      };

      fetchQuizStatus();
    } else {
      setIsLoggedIn(false);
      setIsGuest(false);
    }
  }, [currentUser]);

  return (
    <div className="main-content">
      <div className="home-container">
        <div className="main-content-home">
          <div className="left-column">
            {isLoggedIn && !isGuest && (
              <>
                <div className="mobile-widget" role="button" onClick={() => navigate('/Game')}>
                  <h2>Play a game!</h2>
                </div>
                <div className="mobile-widget" role="button" onClick={() => navigate('/virtualstudy')}>
                  <h2>Virtual Study</h2>
                </div>
              </>
            )}
            {isLoggedIn && !isGuest && !isQuizComplete && (
              <div className="widget" role="button" onClick={() => navigate('/Quiz')}>
                <h2>Coffee Quiz</h2>
              </div>
            )}
            <div className="widget" role="button" onClick={() => navigate('/Surpriseme')}>
              <h2>Surprise Me</h2>
            </div>
            <div className="widget" role="button" onClick={() => navigate('/News')}>
              <h2>Coffee News</h2>
            </div>
          </div>

          <div className="map-column">
            <MapComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
