import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc, arrayUnion, arrayRemove, updateDoc } from 'firebase/firestore';
import './InfoPanel.css';

const InfoPanel = ({ shop, onClose }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const checkIfFavorite = async () => {
      if (!shop) return;

      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const docRef = doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const favorites = docSnap.data().favorites || [];
          const isFav = favorites.some(fav => fav.name === shop.name && fav.address === shop.address);
          setIsFavorite(isFav);
        }
      }
      setLoading(false);
    };

    checkIfFavorite();
  }, [shop]);

  const showNotification = (message, isSuccess) => {
    setNotification({ message, isSuccess });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const addToFavorites = async () => {
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;
      const shopData = {
        name: shop.name,
        address: shop.address,
        lat: shop.lat,
        lng: shop.lng,
        rating: shop.rating,
        distance: shop.distance,
      };

      try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          favorites: arrayUnion(shopData),
        });
        setIsFavorite(true);
        showNotification('Successfully added to favorites!', true);
      } catch (error) {
        console.error('Error adding to favorites: ', error);
        showNotification('Failed to add to favorites.', false);
      }
    } else {
      showNotification('Please log in to add to favorites.', false);
    }
  };

  const removeFromFavorites = async () => {
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;
      const shopData = {
        name: shop.name,
        address: shop.address,
        lat: shop.lat,
        lng: shop.lng,
        rating: shop.rating,
        distance: shop.distance,
      };

      try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          favorites: arrayRemove(shopData),
        });
        setIsFavorite(false);
        showNotification('Successfully removed from favorites!', true);
      } catch (error) {
        console.error('Error removing from favorites: ', error);
        showNotification('Failed to remove from favorites.', false);
      }
    }
  };

  if (!shop) {
    return null;
  }

  const { name, address, rating, distance, lat, lng } = shop;

  return (
    <div className="panel">
      <div className="panelheader">
        <h2>{name}</h2>
        <span className="closeButton" title="Close" onClick={onClose}>X</span>
      </div>
      <div className="info-content">
        <p><strong>Address:</strong> {address}</p>
        <p><strong>Rating:</strong> {rating || 'N/A'}</p>
        <p><strong>Distance:</strong> {distance} miles away</p>
        <div className="buttons">
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="directionsLink"
          >
            Get Directions
          </a>
        </div>
        <div className="buttons">
          {auth.currentUser ? (
            <>
              {isFavorite ? (
                <button onClick={removeFromFavorites} className="button-style">Remove from Favorites</button>
              ) : (
                <button onClick={addToFavorites} className="button-style">Add to Favorites</button>
              )}
            </>
          ) : (
            <p>You must be logged in to add or remove favorites.</p>
          )}
        </div>
      </div>

      {notification && (
        <div className={`info-notification ${notification.isSuccess ? 'info-success' : 'info-error'}`}>
          <span>{notification.message}</span>
          <button className="info-notification-close" onClick={() => setNotification(null)}>&#10006;</button>
        </div>
      )}
    </div>
  );
};

export default InfoPanel;
