import React, { useEffect, useState } from 'react';
import './News.css';

function News() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchNews = async () => {
        try {
            const query = 'coffee';
            const response = await fetch(`/api/news?query=${encodeURIComponent(query)}`);

            if (!response.ok) {
                throw new Error('Failed to fetch news. Please try again later.');
            }

            const data = await response.json();

            if (data && data.articles && data.articles.length > 0) {
                setArticles(data.articles);
            } else {
                setArticles([]);
                throw new Error('No articles found for the given query.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="main-content">
            <div className="coffee-news-container">
                <h1>Coffee News</h1>
                {articles.length === 0 ? (
                    <p>No news articles found.</p>
                ) : (
                    <ul>
                        {articles.map((article, index) => (
                            <li key={index} className="coffee-news-article">
                                <h2>{article.title}</h2>
                                <p>{article.description}</p>
                                <a href={article.url} target="_blank" rel="noopener noreferrer">Read more</a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default News;