import React, { useEffect, useCallback } from 'react';

const CoffeeBackground = () => {
    const spawnInterval = 1000;
    const maxBeans = 30;
    const beanSize = 50;
  
    const getRandomInt = useCallback((min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }, []);
  
    const createCoffeeBean = useCallback(() => {
      const bean = document.createElement('div');
      bean.classList.add('coffee-bean');
      
      // Ensure we're using the full viewport
      const x = getRandomInt(0, window.innerWidth - beanSize);
      const y = getRandomInt(0, window.innerHeight - beanSize);
      
      bean.style.left = `${x}px`;
      bean.style.top = `${y}px`;
      
      const container = document.querySelector('.coffee-background');
      if (container) {
        container.appendChild(bean);
      }
      
      bean.addEventListener('animationend', () => {
        bean.remove();
      });
    }, [getRandomInt]);
  
    const spawnBeans = useCallback(() => {
      if (document.querySelectorAll('.coffee-bean').length < maxBeans) {
        createCoffeeBean();
      }
    }, [createCoffeeBean]);
  
    useEffect(() => {
      const interval = setInterval(spawnBeans, spawnInterval);
      
      return () => {
        clearInterval(interval);
      };
    }, [spawnBeans]);
  
    return <div className="coffee-background" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }} />;
  };
export default CoffeeBackground;