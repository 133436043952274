import React, { useState, useEffect } from 'react'; 
import { doc, getDoc, setDoc, updateDoc, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { auth, db } from './firebase';
import './Game.css';

const Game = () => {
  const [cupPosition, setCupPosition] = useState(0);
  const [beans, setBeans] = useState([]);
  const [score, setScore] = useState(0);
  const [highestScore, setHighestScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [gameMode, setGameMode] = useState(null);
  const [timeLeft, setTimeLeft] = useState(120);
  const [gameStarted, setGameStarted] = useState(false);

  const user = auth.currentUser;
  const cupWidth = 100;
  const gameWidth = window.innerWidth * 0.65;
  const gameHeight = window.innerHeight * 0.80;
  const maxBeanFallSpeed = 8;
  const minBeanFallSpeed = 4;
  const maxBeansOnScreen = 5;
  let beanFallSpeed = minBeanFallSpeed;

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userDoc = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists()) {
          setHighestScore(userSnap.data().highestScore || 0);
        } else {
          await setDoc(userDoc, { highestScore: 0 }, { merge: true });
        }
      };

      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const leaderboardQuery = query(
        collection(db, 'leaderboard'),
        orderBy('score', 'desc'),
        limit(5)
      );
      const querySnapshot = await getDocs(leaderboardQuery);
      const leaderboardData = querySnapshot.docs.map(doc => doc.data());
      setLeaderboard(leaderboardData);
    };

    fetchLeaderboard();
  }, [gameOver]);

  const startGame = (mode) => {
    setGameMode(mode);
    setScore(0);
    setCupPosition(gameWidth / 2 - cupWidth / 2);
    setGameOver(false);
    setBeans([]);
    beanFallSpeed = minBeanFallSpeed;
    setGameStarted(true);
    if (mode === 'survivor') {
      setTimeLeft(60);
    }
    generateBean();
  };

  const generateBean = () => {
    setBeans((prev) => {
      if (prev.length >= maxBeansOnScreen) {
        return prev;
      }
      const newBean = {
        position: -50,
        left: Math.random() * (gameWidth - 50),
      };
      return [...prev, newBean];
    });

    setTimeout(generateBean, Math.random() * (6000 - 4000) + 4000);
  };

  const handleKeyDown = (e) => {
    if (gameOver) return;
    if (e.key === 'ArrowLeft') {
      setCupPosition((prev) => Math.max(0, prev - 20));
    } else if (e.key === 'ArrowRight') {
      setCupPosition((prev) => Math.min(gameWidth - cupWidth, prev + 20));
    }
  };

  useEffect(() => {
    const gameInterval = setInterval(() => {
      if (!gameOver) {
        setBeans((prevBeans) => {
          return prevBeans.map((bean) => {
            const newPosition = bean.position + beanFallSpeed;
            return { ...bean, position: newPosition };
          });
        });
      }
    }, 100);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      clearInterval(gameInterval);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [gameOver]);

  useEffect(() => {
    const checkCollision = () => {
      beans.forEach((bean, index) => {
        if (
          bean.position + 50 >= gameHeight &&
          bean.position <= gameHeight + 60 &&
          cupPosition + cupWidth >= bean.left &&
          cupPosition <= bean.left + 50
        ) {
          setScore((prev) => {
            const newScore = prev + 1;
            if (newScore > highestScore) {
              setHighestScore(newScore);
              updateUserHighestScore(newScore);
            }
            return newScore;
          });
          setBeans((prevBeans) => {
            const newBeans = [...prevBeans];
            newBeans[index] = { ...bean, position: -50, left: Math.random() * (gameWidth - 50) };
            return newBeans;
          });

          if (gameMode === 'survivor') {
            beanFallSpeed = Math.min(maxBeanFallSpeed, beanFallSpeed * 2);
          }
        } else if (bean.position > gameHeight) {
          if (gameMode === 'normal') {
            setGameOver(true);
            updateLeaderboard(score);
          }
        }
      });
    };
    if (!gameOver) {
      checkCollision();
    }
  }, [beans, cupPosition, gameOver, score, gameMode]);

  useEffect(() => {
    if (gameMode === 'survivor' && gameStarted) {
      if (timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else {
        setGameOver(true);
        updateLeaderboard(score);
      }
    }
  }, [timeLeft, gameMode, gameStarted]);

  const updateUserHighestScore = async (newScore) => {
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, { highestScore: newScore });
    }
  };

  const updateLeaderboard = async (finalScore) => {
    if (user) {
      const leaderboardEntry = {
        username: user.displayName || 'Anonymous',
        score: finalScore,
      };
      await setDoc(doc(collection(db, 'leaderboard')), leaderboardEntry);
    }
  };

  return (
    <div className="game-backdrop">
      <div className="game-container" style={{ width: gameWidth, height: gameHeight }}>
        {gameMode === 'survivor' && !gameOver && (
          <div className="timer" style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '18px', backgroundColor: '#f4f4f4', padding: '5px 10px', borderRadius: '5px', fontWeight: 'bold' }}>
            Time Left: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
          </div>
        )}
        <div className="score">Score: {score}</div>
        {beans.map((bean, index) => (
          <img key={index} src="/markericon.png" alt="Coffee Bean" className="coffee-bean" style={{ position: 'absolute', top: bean.position, left: bean.left, width: '50px', height: '50px' }} />
        ))}
        <img src="/cute-coffee.png" alt="Coffee Cup" className="coffee-cup" style={{ position: 'absolute', bottom: '0', left: cupPosition, width: cupWidth, height: '60px' }} />

        {!gameStarted && (
          <div className="mode-selection">
            <button onClick={() => startGame('normal')} style={{ marginRight: '10px', padding: '10px 20px', fontSize: '16px', backgroundColor: '#FF6347', color: '#fff', border: 'none', borderRadius: '5px' }}>
              Normal Mode
            </button>
            <button onClick={() => startGame('survivor')} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#32CD32', color: '#fff', border: 'none', borderRadius: '5px' }}>
              Survivor Mode
            </button>
          </div>
        )}

        {gameOver && gameStarted && (
          <div className="game-over-message">
            <p>Game Over!</p>
            <p>Your final score: {score}</p>
          </div>
        )}
      </div>

      <div className="leaderboard" style={{ marginLeft: '20px', textAlign: 'left' }}>
        <h3>Highest Score: {highestScore}</h3>
        <h3>Leaderboard</h3>
        <ul>
          {leaderboard.map((entry, index) => (
            <li key={index}>{entry.username}: {entry.score}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Game;
