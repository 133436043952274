import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './UserProfile.css';

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState({
    username: '',
    firstName: '',
    lastName: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const docRef = doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      try {
        await setDoc(doc(db, 'users', userId), userInfo, { merge: true });
        setEditMode(false);
      } catch (error) {
        console.error('Error updating user data: ', error);
        alert('Failed to save your changes.');
      }
    }
    setIsSaving(false);
  };

  if (loading) {
    return <div>Loading your profile...</div>;
  }

  return (
    <div className="profile-container">
      {/* Welcome message */}
      <h1>Welcome, {userInfo.username}!</h1>
      
      <div className="profile-info">
        <label>Username:</label>
        {editMode ? (
          <input
            type="text"
            name="username"
            value={userInfo.username}
            onChange={handleChange}
          />
        ) : (
          <p>{userInfo.username}</p>
        )}
      </div>

      <div className="profile-info">
        <label>First Name:</label>
        {editMode ? (
          <input
            type="text"
            name="firstName"
            value={userInfo.firstName}
            onChange={handleChange}
          />
        ) : (
          <p>{userInfo.firstName}</p>
        )}
      </div>

      <div className="profile-info">
        <label>Last Name:</label>
        {editMode ? (
          <input
            type="text"
            name="lastName"
            value={userInfo.lastName}
            onChange={handleChange}
          />
        ) : (
          <p>{userInfo.lastName}</p>
        )}
      </div>

      <div className="profile-actions">
        {editMode ? (
          <button onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        ) : (
          <button onClick={() => setEditMode(true)}>Edit Profile</button>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
