import React, { useState } from 'react';
import { db, auth } from './firebase';
import { doc, setDoc } from 'firebase/firestore';
import './Quiz.css';

const Quiz = ({ onQuizComplete }) => {
  const [result, setResult] = useState('');
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userId) {
      console.log('User is not logged in.');
      return;
    }

    const coffeeTypeInput = event.target.coffeeType.value;
    const noPreference = event.target.noPreference.checked;
    const coffeePreference = noPreference ? "No preference" : coffeeTypeInput || "No answer";

    const pricePreference = event.target.price.value || "No answer";
    const insideSpacePreference = event.target.insideSpace.value || "No answer";
    const crowdednessPreference = event.target.crowdedness.value || "No answer";
    const distancePreference = event.target.distance.value || "No answer";

    // Set result to display after submission
    setResult(`
      <h2>Your Answers:</h2>
      <p>Coffee Type: ${coffeePreference}</p>
      <p>Price Preference: ${pricePreference}</p>
      <p>Inside Space: ${insideSpacePreference}</p>
      <p>Crowdedness Preference: ${crowdednessPreference}</p>
      <p>Distance Preference: ${distancePreference}</p>
    `);

    try {
      await setDoc(doc(db, "users", userId, "quizResponses", "response1"), {
        coffeeType: coffeePreference,
        price: pricePreference,
        insideSpace: insideSpacePreference,
        crowdedness: crowdednessPreference,
        distance: distancePreference,
      });
      setIsQuizSubmitted(true);
      onQuizComplete();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="quiz-content">
      <div className="quiz-container">
        <div className="title-container">
          <h1>🤎Find Your Perfect Coffee Shop</h1>
          <img src="https://c.tenor.com/xAeOEOBboE8AAAAC/mocha-bear-coffee.gif" alt="Coffee Bear GIF" className="title-image" />
        </div>

        {!isQuizSubmitted && (
          <form onSubmit={handleSubmit}>
            <div className="question">
              <p>Q1: What kind of coffee do you prefer?</p>
              <input type="text" id="coffeeType" name="coffeeType" placeholder="Type your preference here" />
              <label>
                <input type="checkbox" id="noPreference" name="noPreference" /> ☕︎ I don't have a preference
              </label>
            </div>

            <div className="question">
              <p>Q2: Do you like your coffee cheap?</p>
              <label>
                <input type="radio" name="price" value="cheap" /> Yes
              </label><br />
              <label>
                <input type="radio" name="price" value="quality" /> No
              </label><br />
              <label>
                <input type="radio" name="price" value="dontCare" /> I don't care
              </label>
            </div>

            <div className="question">
              <p>Q3: Inside space availability?</p>
              <label>
                <input type="radio" name="insideSpace" value="yes" /> Yes, please
              </label><br />
              <label>
                <input type="radio" name="insideSpace" value="dontCare" /> Don't care
              </label>
            </div>

            <div className="question">
              <p>Q4: Do you care about crowdedness/average wait time after your order?</p>
              <label>
                <input type="radio" name="crowdedness" value="yes" /> Yes, I'm always on the run
              </label><br />
              <label>
                <input type="radio" name="crowdedness" value="no" /> Nope, quality over time
              </label>
            </div>

            <div className="question">
              <p>Q5: Distance preference?</p>
              <label>
                <input type="radio" name="distance" value="10" /> 10 miles
              </label><br />
              <label>
                <input type="radio" name="distance" value="5" /> 5 miles
              </label><br />
              <label>
                <input type="radio" name="distance" value="1" /> 1 mile
              </label>
            </div>

            <button type="submit" id="submit">Done</button>
          </form>
        )}

        <div id="result" dangerouslySetInnerHTML={{ __html: result }} />
      </div>
    </div>
  );
};

export default Quiz;
